body{
  font-family: 'Open Sans', sans-serif;
  background-color: rgb(13, 19, 24);
  display: grid;
  grid-template-columns: 1fr;
  height: 2000px;
  color: white;
}

a{
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  color: white;
}

a:hover{
  cursor: pointer;
  font-weight: bolder;
}

.main-body-container{
  margin-top: 65px;
  scroll-margin-top: 400px;
}

.hidden{
  opacity: 0;
  transform: translateX(-30%);
  margin-top: 400px;
  margin-bottom: 800px;
  transition: 1s;
  text-align: center;
  font-weight: bolder;
  font-size: 80px;
}

.show{
  opacity: 1;
  transform: translateX(0%);
}

.contact-container{
  text-align: center;
  margin-top: -300px;
  margin-bottom: 70px;
  font-weight: bolder;
  font-size: 30px;
}

.project-word{
  text-align: center;
  font-weight: bolder;
  font-size: 38px;
  margin-bottom: 100px;
}

.summary-container{
  text-align: center;
  margin-bottom: 70px;
  margin-top: -800px;
  margin-bottom: 400px;
}

.bot-description,
.project-description,
.summary{
  font-weight: bolder;
  font-size: 30px;
  padding-left: 10%;
  padding-right: 10%
}

.projects-container{
  margin-top: -300px;
  text-align: center;
  scroll-margin: 300px;
}

.project-title{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 40px;
}

.project-description{
  margin-top: -750px;
  font-size: 22px;
} 

.bot-img-container{
  margin-top: -400px;
}

.bot-image{
  margin-left: 20%;
}

.bot-description{
  margin-top: -750px;
  font-size: 22px;
}

.castle-image{
  margin-top: -30px;
  width:800px;
}

.bot-img-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.my-image-container{
  text-align: center;
}

.chon{
  width:600px;
}